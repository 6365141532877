@import '~react-image-gallery/styles/css/image-gallery.css';

:root {
  --primary-color: #d79225 !important;
  --shadow-color: #e3c390;
  --secondry-color: #110d0e;
  --bc-color: #fff;
  --secondry-bc-color: #000;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: url('./assets/images/background.png');
  /* background-position: center;
  background-size: cover;
  background-repeat: no-repeat; */
  position: relative;
}
html {
  font-family: 'Roboto', sans-serif;
}

span.p-dialog-header-close-icon.pi.pi-times {
  color: var(--primary-color);
}
/* table styles */
.p-dialog-right.p-dialog {
  margin: 0;
  max-height: auto;
}
.p-dialog .p-dialog-header {
  padding: 1rem !important;
}
